<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2023-08-04 17:25 -->
<!-- Description:  -->
<template>
  <div>
    <div>
      <div class="row mb-2">
        <div class="col">
          <label for="">Area</label>
          <AreaSelect
            :value="area_id"
            @input="
              area_id = $event;
              $refs.elTr.gl();
            "
          ></AreaSelect>
        </div>
        <div class="col"></div>
        <div class="col"></div>
      </div>
    </div>
    <app-table-registers
      ref="elTr"
      :getList="getList"
      @btnNew="
        $refs.elModalServiceForm.show();
        $refs.elServiceForm.reset();
      "
    >
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Area</th>
            <th class="text-right">Precio</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in list" :key="l.id">
            <td>{{ l.name }}</td>
            <td>{{ l.area_id }}</td>
            <td class="text-right">
              <app-span-money
                :quantity="l.unit_price"
                :moneyCode="l.money_code"
              ></app-span-money>
            </td>
            <td>
              <button
                class="btn btn-primary btn-sm"
                @click="
                  $refs.elModalServiceForm.show();
                  $refs.elServiceForm.setValue(l);
                "
              >
                <i class="fas fa-edit"></i>
              </button>
              <button
                v-if="l.type_id == 2"
                class="btn btn-primary btn-sm"
                @click="modifyProductState(l)"
              >
                <i class="fas fa-exchange-alt"></i>
              </button>
              <button class="btn btn-primary btn-sm" @click="deleteItem(l.id)">
                <i class="fas fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </app-table-registers>

    <app-modal-basic ref="elModalProductStateForm">
      <ProductStateForm
        ref="elProductStateForm"
        @submitted="$refs.elModalProductStateForm.hide()"
      ></ProductStateForm>
    </app-modal-basic>

    <app-modal-basic ref="elModalServiceForm">
      <ServiceForm
        ref="elServiceForm"
        @submitted="
          $refs.elModalServiceForm.hide();
          $refs.elTr.gl();
        "
      ></ServiceForm>
    </app-modal-basic>
  </div>
</template>
<script>
import { StoreService } from "src/lt/store-module/StoreService.js";
import AreaSelect from "src/lt/admin-module/areas/Select.vue";
import ProductStateForm from "src/dental-clinic-module/odontogram/product-states/Form.vue";
import ServiceForm from "src/lt/store-module/products/ServiceForm.vue";
import { DentalClinicService } from "src/dental-clinic-module/service.js";

export default {
  name: "ProductList",
  components: {
    AreaSelect,
    ProductStateForm,
    ServiceForm,
  },

  // directives

  props: {
    //
  },

  data: () => ({
    list: [],
    area_id: null,
  }),

  computed: {
    //
  },

  // watch: {},

  created() {
    // this.area_id = this.$route.query.area_id;
  },

  mounted() {
    //
  },

  methods: {
    async getList(params) {
      var res = await StoreService.getProducts({
        ...params,
        area_id: this.area_id,
        categorie_id: this.$route.query.categorie_id,
      });
      this.list = res.data;
      return res;
    },
    async deleteItem(id) {
      if (window.confirm("Eliminar el registro?")) {
        await StoreService.deleteProduct(id);
        this.$refs.elTr.gl();
      }
    },
    async modifyProductState(product) {
      var states = await DentalClinicService.getProductStates({
        product_id: product.id,
      });
      if (states.data.length > 0) {
        this.$refs.elModalProductStateForm.show();
        this.$refs.elProductStateForm.setValue(states.data[0]);
      } else {
        this.$refs.elModalProductStateForm.show();
        this.$refs.elProductStateForm.setValue({
          id: null,
          product_id: product.id,
          status: null,
          status_target: null,
          deleted_at: null,
          discount_lab_cost: null,
          lab_discount: null,
          product_name: product.name,
          unit_price: product.unit_price,
        });
      }
    },
    //
    //
  },
};
</script>
<style scoped></style>
